import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { graphql } from 'gatsby';
import { get, map, isEmpty } from 'lodash';
import { withStyles, Container } from '@material-ui/core';

import ContactPersonModule from '../../module/ContactPersonModule';

const styles = theme => ({
  root: {},
  contactPersons: {
    display: 'flex',
    flexDirection: 'column',
  },
});

function ContactPersons({ classes, className, data: contactPersons }) {
  if (isEmpty(contactPersons)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="md">
        <div className={classes.contactPersons}>
          {map(contactPersons, (contactPerson, index) => {
            const contactPersonId = get(contactPerson, 'contentful_id');
            const contactPersonKey = `${contactPersonId}-${index}`;

            return <ContactPersonModule key={contactPersonKey} data={contactPerson} />;
          })}
        </div>
      </Container>
    </div>
  );
}

ContactPersons.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.array,
};

ContactPersons.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export const query = graphql`
  fragment ContactPersonFragment on ContentfulContactPersonModule {
    contentful_id
    name
    internal {
      type
    }
    phoneNumber
    role
    emailAddress
    cloudinaryImage {
      secure_url
      public_id
      raw_transformation
      width
      height
    }
  }
`;

export default withStyles(styles)(ContactPersons);
