import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles, Container } from '@material-ui/core';

import RichText from '../../shared/RichText';

const styles = theme => ({
  root: {
    marginBottom: '40px',
  },
  description: {
    textAlign: 'center',
  },
});

function SocialMediaSharing({ classes, className, pageData }) {
  const description = get(pageData, 'socialMediaSharing.description');

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <RichText data={description} pageData={pageData} className={classes.description} />
      </Container>
    </div>
  );
}

SocialMediaSharing.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.object,
};

SocialMediaSharing.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(SocialMediaSharing);

export const query = graphql`
  fragment SocialMediaSharingSocialMediaElementFragment on ContentfulSocialMediaElement {
    __typename
    contentful_id
    title
    socialMediaLink
    internal {
      type
    }
  }

  fragment SocialMediaSharingDescriptionReferenceFragment on Node {
    ...SocialMediaSharingSocialMediaElementFragment
  }

  fragment SocialMediaSharingFragment on ContentfulTextBlock {
    title
    description {
      raw
      references {
        ...SocialMediaSharingDescriptionReferenceFragment
      }
    }
  }
`;
