import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isEmpty } from 'lodash';
import { withStyles, Container } from '@material-ui/core';

import RichText from '../../shared/RichText';
import LeadTextModule from '../../module/LeadTextModule';

const styles = theme => ({
  root: {
    margin: '0',
    padding: '0 20px',
    [theme.breakpoints.up('md')]: {
      padding: '0',
    },
  },
});

function LeadText({ classes, className, data }) {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="md">
        <LeadTextModule data={{ description: data }} richText={RichText} />
      </Container>
    </div>
  );
}

LeadText.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.string,
};

LeadText.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(LeadText);
