import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isEmpty } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles, Container } from '@material-ui/core';

import LatestArticlesModule from '../../module/LatestArticlesModule';

const styles = theme => ({
  root: {},
});

function LatestArticles({ classes, className, data }) {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <LatestArticlesModule data={data} />
      </Container>
    </div>
  );
}

LatestArticles.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

LatestArticles.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export const query = graphql`
  fragment LatestArticlesFragment on ContentfulLatestArticlesModule {
    contentful_id
    title
    internal {
      type
    }
    articlePages {
      contentful_id
      fullPath
      publishingDate
      title
      description
      categories {
        contentful_id
        title
        searchPage {
          ... on ContentfulAdvancedPage {
            fullPath
          }
        }
        heroImage {
          imageTitleText
          imageAltText
          cloudinaryImage {
            secure_url
            public_id
            raw_transformation
          }
        }
      }
      heroImage {
        imageTitleText
        imageAltText
        cloudinaryImage {
          secure_url
          public_id
        }
      }
    }
  }
`;

export default withStyles(styles)(LatestArticles);
