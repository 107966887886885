/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Hyphenation from '../components/shared/Hyphenation';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Breadcrumbs from '../components/navigation/Breadcrumbs';

import SeoFields from '../components/page/SeoFields';
import HeroImage from '../components/page/HeroImage';
import Title from '../components/page/Title';
import LeadText from '../components/page/LeadText';
import Content from '../components/page/Content';
import ContentList from '../components/page/ContentList';
import LatestArticles from '../components/page/LatestArticles';
import ContactPersons from '../components/page/ContactPersons';
import SocialMediaSharing from '../components/page/SocialMediaSharing';

import useInitI18next from '../components/hooks/useInitI18next';
import useInitLuxon from '../components/hooks/useInitLuxon';

const styles = theme => ({
  title: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0),
      marginTop: theme.spacing(6),
    },
  },
  content: {
    marginTop: theme.spacing(0),
  },
});

function AdvancedPageTemplate({ classes, data }) {
  const page = get(data, 'contentfulAdvancedPage');
  const breadcrumbs = get(page, 'breadcrumbs');
  const heroImage = get(page, 'heroImage');
  const title = get(page, 'title');
  const description = get(page, 'description');
  const content = get(page, 'content');
  const contentList = get(page, 'contentList.0');
  const relatedContactPersons = get(page, 'relatedContactPersons');
  const relatedArticles = get(page, 'relatedArticles');

  useInitI18next();
  useInitLuxon();

  return (
    <>
      <Hyphenation />
      <SeoFields data={page} />
      <Header data={page} />
      <main>
        <HeroImage data={heroImage} height={430} />
        <Breadcrumbs data={breadcrumbs} />
        <Title data={title} className={classes.title} />
        <LeadText data={description} />
        <Content data={content} className={classes.content} />
        <ContentList data={contentList} />
        <LatestArticles data={relatedArticles} />
        <ContactPersons data={relatedContactPersons} />
        <SocialMediaSharing pageData={page} />
      </main>
      <Footer data={page} />
    </>
  );
}

export const pageQuery = graphql`
  query AdvancedPageTemplate($id: String!) {
    contentfulAdvancedPage(id: { eq: $id }) {
      fullPath
      title
      menuTitle
      description
      breadcrumbs {
        ...BreadcrumbPageFragment
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      footer {
        ...FooterFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      content {
        raw
        references {
          ...ContentReferenceFragment
        }
      }
      contentList {
        ...ContentListFragment
      }
      relatedArticles {
        ...LatestArticlesFragment
      }
      relatedContactPersons {
        ...ContactPersonFragment
      }
      socialMediaSharing {
        ...SocialMediaSharingFragment
      }
      seoFields {
        ...SeoDataFragment
      }
    }
  }
`;

AdvancedPageTemplate.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
};

AdvancedPageTemplate.defaultProps = {
  classes: {},
  data: null,
};

export default withStyles(styles)(AdvancedPageTemplate);
