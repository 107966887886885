import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isEmpty } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles, Container } from '@material-ui/core';

import ContentListModule from '../../module/ContentListModule';
import RichText from '../../shared/RichText';

import '../Content'; // for fragment

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.breadcrumbs,
    margin: '40px 0',
    padding: '40px 0 40px 0',
  },
});

function ContentList({ classes, className, data }) {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <ContentListModule data={data} richText={RichText} />
      </Container>
    </div>
  );
}

ContentList.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

ContentList.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export const query = graphql`
  fragment ContentListElementFragment on ContentfulContentListElement {
    contentful_id
    title
    image {
      ...ContentImageModuleFragment
    }
    description {
      raw
      references {
        ... on Node {
          ...ContentIFrameModuleFragment
          ...ContentFeedReaderWidgetModuleFragment
        }
      }
    }
    pageReference {
      ... on ContentfulArticlePage {
        contentful_id
        title
        description
        fullPath
        heroImage {
          ...ContentHeroImageModuleFragment
        }
      }
      ... on ContentfulAdvancedPage {
        contentful_id
        title
        description
        fullPath
        heroImage {
          ...ContentHeroImageModuleFragment
        }
      }
    }
  }

  fragment ContentListFragment on Node {
    ... on ContentfulContentListModule {
      contentful_id
      title
      internal {
        type
      }
      contentList {
        ...ContentListElementFragment
      }
    }
  }
`;

export default withStyles(styles)(ContentList);
