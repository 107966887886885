import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isEmpty } from 'lodash';
import { withStyles, Typography, Container } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: '20px ',
    [theme.breakpoints.up('md')]: {
      padding: '20px 0',
    },
  },
  text: {
    overflowWrap: 'break-word',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
});

function Title({ classes, className, data }) {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="md">
        <Typography variant="h1" className={classes.text}>
          {data}
        </Typography>
      </Container>
    </div>
  );
}

Title.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.string,
};

Title.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(Title);
